import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h6 className="heading-h2"><span className="heading-h3"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h6>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is P2P Crypto Exchange Development?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">A P2P or Peer-to-Peer Crypto Exchange is a unique type of trading platform that runs through an Escrow-based system and allows users to trade directly without a third party in between. Coinsclone is a prominent P2P Crypto Exchange development company having successfully developed a number of P2P exchanges. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Why do startups prefer P2P Exchange over other exchanges?
                </Accordion.Toggle>
                
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">P2P Exchanges do not require the role of a central authority and hence, the assets are under the control of the users. Also, P2P Crypto Exchanges have additional benefits such as reduced trading fees for users. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How does a P2P Exchange work?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">
                  <p>Peer-to-peer exchanges facilitate direct trading between users without involving an intermediary. The P2P Exchanges come under the decentralization concept since no third-party interaction takes place and assets.</p>
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How much does it cost to build a P2P crypto exchange?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">A P2P Crypto Exchange development costs in the range of 20,000 to 1,00,000 based on the development method you choose. When you choose a P2P Crypto Exchange script, the development cost is reduced considerably.  
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How to create a P2P Crypto Exchange Platform?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">You can create a decentralized Peer-to-peer Exchange Platform by approaching a prominent development company like Coinsclone. We offer three popular development methods (Scratch, White Label, and Clone Script) to launch a P2P exchange.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                How long does it take to create a P2P Cryptocurrency exchange?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Developing a P2P Crypto Exchange from scratch takes around 10-12 months based on the requirements. Using our pre-made P2P Crypto exchange software development, you can build your P2P exchange within a week or 10 days. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle" variant="link" eventKey="6">
                What is the future of P2P Crypto trading?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">P2P Crypto Trading is a revolutionary change in terms of decentralization. P2P offers a unique and different way of trading Crypto assets and assures users of more security, Thus, the Crypto community becomes enlarged with the peer-to-peer trading option.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection