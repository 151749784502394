import React from 'react' 

const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Coinsclone </span>is the Best</span>
             P2P Crypto Exchange Development Company?</h3>
            <p className="pharagraph mb-1">Coinsclone, an esteemed P2P Crypto exchange development company are expert in producing a feature-rich P2P platform for Clients. We simplify the process of P2P Cryptocurrency exchange development with our high-grade peer-to-peer exchange clone scripts and White Label P2P solutions. As a result, you can reach the market quickly and build a strong brand for your exchange platform with minimal effort.
            </p>
            <p className="pharagraph">With more than 10 years of experience in the blockchain industry and 100+ developers, we have readymade solutions for all types of Crypto exchange development. Additionally, we ensure timely maintenance and customize the P2P exchange with top-notch features for a creative experience. Launch your decentralized P2P exchange platform with our services and gain massive profits at affordable rates.  
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className="lazyload" width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/p2p/complete-branding.svg" alt="Complete Branding" />
                </div>
              </div>
              <div className="content-rght">
                <p className="listing">
                Complete Branding
                </p>
                <p className="pharagraph">
                You will have a unique and customized P2P trading platform with an exclusive identity.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className="lazyload" width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/p2p/ongoing-support.svg" alt=" Ongoing Support" />
                </div>
              </div>
              <div className="content-rght">
                <p className="listing">
                Ongoing Support
                </p>
                <p className="pharagraph">
                Aside from designing and development we also offer ongoing support so, you are never on your own.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className="lazyload" width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/p2p/transparency.svg" alt="Transparency" />
                </div>
              </div>
              <div className="content-rght">
                <p className="listing">
                  Transparency
                </p>
                <p className="pharagraph">
                There are no hidden costs or surprises when you get your P2P Crypto exchange developed by our team.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className="lazyload" width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/p2p/script-ownership.svg" alt="Script Ownership" />
                </div>
              </div>
              <div className="content-rght">
                <p className="listing">
                  Script Ownership
                </p>
                <p className="pharagraph">
                You completely own the script once the platform code has been installed and activated on your server.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className="lazyload" width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/p2p/scalable-development.svg" alt="Scalable Development" />
                </div>
              </div>
              <div className="content-rght">
                <p className="listing">
                  Scalable Development
                </p>
                <p className="pharagraph">
                We provide our P2P Crypto trading development and programming services for small, medium-sized, and big businesses.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className="lazyload" width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/p2p/custom-add-ons.svg" alt="Custom Add-Ons" />
                </div>
              </div>
              <div className="content-rght">
                <p className="listing">
                  Custom Add-Ons
                </p>
                <p className="pharagraph">
                We can build custom add-ons with the software we create to extend your existing P2P trading platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose